//Lib variables
@import "vendor/magento-ui/variables/colors";
@import "vendor/magento-ui/variables/typography";
@import "vendor/magento-ui/variables/layout";

// Vendor libs
@import 'vendor/normalize/lib'; // Normalize.css
@import 'vendor/magento-ui/lib'; // Magento UI

// Theme variables
@import 'variables';

//Custom variables
@import 'custom-variables';

// Magento/blank
.page-print {
    .logo {
        float: none;
        display: block;
        text-align: left;
    }
}

@media print {
    * {
        background: transparent !important;
        color: black !important;
        text-shadow: none !important;
        -webkit-filter: none !important; // Use in 41 Chrome
        filter: none !important;
    }

    // Black prints faster:h5bp.com/s
    a,
    a:visited {
        text-decoration: underline !important;
    }

    // Don't show links for images, or javascript/internal links
    pre,
    blockquote {
        border: 1px solid $color-gray60;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    .table-wrapper table {
        width: 100%;

        td {
            width: auto;
        }

        table-layout: fixed;

        & > tfoot > tr:first-child {
            border-top: 1px solid $color-gray60;
        }
    }

    .box,
    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    @page {
        margin: 1cm;
    }

    .block-content,
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    .block-content {
        page-break-before: avoid;
    }

    .block-title,
    h2,
    h3 {
        page-break-after: avoid;
    }

    .nav-toggle {
        display: none !important;
    }

    .sidebar,
    .nav-sections,
    .header.content > *[class],
    .panel.wrapper > *[class],
    .footer.content > *[class] {
        display: none;
    }

    .logo,
    .footer .copyright {
        display: block !important;
        margin: 10px 0;
    }

    .order-details-items {
        .order-items {
            .order-gift-message {
                &:not(.expanded-content) {
                    visibility: visible;
                    height: auto;
                }
            }
        }
    }

    .column.main {
        width: 100% !important;
        float: none !important;
    }

    .breadcrumbs {
        margin: 0 auto;
        text-align: right;
    }

    .footer.content {
        padding: 0;
    }
}
